import { InitAppForPageFn } from '@wix/yoshi-flow-editor';
import {
  BatchFetch,
  BatchFetchData,
  initSharedAppStateStore,
} from './shared-app-state/shared-app-state';
import { listAttributeAveragesByEntity } from '@wix/ambassador-ratings-v1-rating/http';

export const initAppForPage: InitAppForPageFn = async (
  _initAppParam,
  _platformUtilitiesApi,
  _scopedGlobalSdkApis,
  _platformServices,
  flowAPI,
) => {
  const httpClient = flowAPI.httpClient;
  const batchFetch: BatchFetch = ({ resourceIds, namespace }) => {
    return httpClient
      .request(
        listAttributeAveragesByEntity({
          group: namespace,
          entityIds: resourceIds,
          namespace: 'reviews',
          attributeId: 'overall',
          attributeName: 'overall',
        }),
      )
      .then(
        (response) => {
          const data =
            response.data.entityAverages?.reduce<BatchFetchData>(
              (acc, { entityId, total, average }) => {
                if (!entityId) {
                  return acc;
                }
                acc[entityId] = { totalReviews: total ?? 0, overall: average ?? 0 };
                return acc;
              },
              {},
            ) ?? {};
          return { type: 'OK', data };
        },
        () => ({ type: 'ERROR' }),
      );
  };
  const sharedAppStateStore = initSharedAppStateStore({ batchFetch });
  if (process.env.NODE_ENV === 'test') {
    // @ts-expect-error
    window.sharedAppStateStore = sharedAppStateStore;
  }
  return {
    sharedAppStateStore,
  };
};
